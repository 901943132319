<template>
  <div>
    <component v-for="s in validSections" :key="s.index" :is="getComponent(s)" :content="s.fields" />
  </div>
</template>

<script>
// Default
import DefaultSection from './common/DefaultSection'

// Custom sections
import FeatureCarousel from './common/FeatureCarousel'
import LocalExperiences from './locations/LocalExperiences'
import ArticleCardHolder from './stories/ArticleCardHolder'
import PhotoCards from './common/PhotoCards'
import PackageSlider from './birthdays/PackageSlider'
import ContactForm from './common/ContactForm'
import PlayAcademyForm from './common/PlayAcademyForm'
import VirtualForm from './common/VirtualForm'
import VirtualShowcaseForm from './common/VirtualShowcaseForm'
import EventSubcategories from './events/EventSubcategories'
import ExperienceList from './experiences/ExperienceList'
import SpecialsList from './specials/SpecialsList'
import BookSelector from './book/BookSelector'
import FoodMenu from './menu/FoodMenu'
import DrinkMenu from './menu/DrinkMenu'
import EventMenu from './menu/EventMenu'
import EventIncludedExperiences from './events/EventIncludedExperiences'
import EventAddOns from './events/EventAddOns'
import SpecialBanner from './common/SpecialBanner'
import EventPackages from './events/EventPackages'
import BirthdayPackages from './birthdays/BirthdayPackages'
import VEPackages from './virtualexperiences/VEPackages'
import DetailsSection from './common/DetailsSection'
import InfoCards from './common/InfoCards'
import LinksSection from './common/LinksSection'
import SiteMapLinks from './common/SiteMapLinks'
import AppFeatures from './app/AppFeatures'
import AppDownload from './app/AppDownload'
import BasicSection from './common/BasicSection'
import Headings from './common/Headings'
import SafetyIcons from './safety/SafetyIcons'
import SafetyHeadings from './safety/SafetyHeadings'
import ImageTextRight from './common/ImageTextRight'
import ImageTextLeft from './common/ImageTextLeft'
import SafetyKnowMore from './safety/SafetyKnowMore'
import LocationSafeWay from './safety/LocationSafeWay'
import CurbsideOrDelivery from './delivery/CurbsideOrDelivery'
import FoodAndFun from './delivery/FoodAndFun'
import DeliveryMenu from './delivery/DeliveryMenu'
import FeedbackCarousel from './common/FeedbackCarousel'
import FeedbackSection from './virtualexperiences/FeedbackSection'
import PlaySmartPlaySafe from './educators/PlaySmartPlaySafe'
import MenuSectionMedia from './menu/MenuSectionMedia'
import TextColumns from './common/TextColumns'
import DoubleColumn from './common/DoubleColumn'
import ImageWithText from './common/ImageWithText'
import Disclaimer from './common/Disclaimer'
import EmarsysBanner from './common/EmarsysBanner'
import TourTravelForm from './common/TourTravelForm'
import Accordian from './common/Accordian'
import CardSection from './common/CardSection'
import Teaser from './common/Teaser'
import TripleColumn from './common/TripleColumn.vue'
import ImageBanner from './common/ImageBanner.vue'

// Luck Components
import LuckHomepageModal from './luck/LuckHomepageModal'

// NCO Page content types
import Countdown from './nco/Countdown'
import ComingSoon from './nco/NCOForm.vue'
import NCOEvents from './nco/NCOEvents'
import NCOHero from './nco/NCOHero'
import NCOOpening from './nco/NCOOpening'

// Lost Pins content types
import LostPinsHero from './lostpins/LostPinsHero'
import LostPinsText from './lostpins/LostPinsText'
import LostPinsClues from './lostpins/LostPinsClues'

// Careers content types
import SubHero from './careers/SubHero'
import MEStory from './careers/MEStory'
import CareerGrowth from './careers/CareerGrowth'
import CurrentOpenings from './careers/CurrentOpenings'
import CultureSection from './careers/CultureSection.vue'
import MissionSection from './careers/MissionSection.vue'
import BenefitsSection from './careers/BenefitsSection.vue'
import CareersSlider from './careers/CareersSlider.vue'

// Custom content types
import Hero from './common/Hero'
import PageHeader from './common/PageHeader'
import CardSlider from './common/CardSlider'
import VideoSlider from './common/VideoSlider'
import Callout from './common/Callout'

// Season Pass components
import SeasonPassForm from './common/SeasonPassForm.vue'


export default {
  props: [
    'sections',
    // 'languageToggle'
  ],
  data() {
    return {
      componentList: {
        'hero': Hero,
        'pageHeader': PageHeader,
        'details-section': DetailsSection,
        'feature-carousel': FeatureCarousel,
        'local-experiences': LocalExperiences,
        'articles': ArticleCardHolder,
        'photo-cards': PhotoCards,
        'package-slider': PackageSlider,
        'contact-form': ContactForm,
        'contact-form-pa': PlayAcademyForm,
        'contact-form-virtual': VirtualForm,
        'contact-form-virtual-showcase': VirtualShowcaseForm,
        'event-subcategories': EventSubcategories,
        'featured-experiences': ExperienceList,
        'specials-list': SpecialsList,
        'booking-selector': BookSelector,
        'food-menu': FoodMenu,
        'drink-menu': DrinkMenu,
        'event-menu': EventMenu,
        'event-included-experiences': EventIncludedExperiences,
        'event-add-ons': EventAddOns,
        'specialBanner': SpecialBanner,
        'event-packages': EventPackages,
        'birthday-packages': BirthdayPackages,
        've-packages': VEPackages,
        'info-cards': InfoCards,
        'card-slider': CardSlider,
        'links-section': LinksSection,
        'app-features': AppFeatures,
        'app-download': AppDownload,
        'sitemap-links': SiteMapLinks,
        'basic-section': BasicSection,
        'video-slider': VideoSlider,
        'headings': Headings,
        'safety-icons': SafetyIcons,
        'safety-headings': SafetyHeadings,
        'image-text-right': ImageTextRight,
        'image-text-left': ImageTextLeft,
        'safety-know-more': SafetyKnowMore,
        'location-safe-way': LocationSafeWay,
        'curbside-or-delivery': CurbsideOrDelivery,
        'food-and-fun': FoodAndFun,
        'delivery-menu': DeliveryMenu,
        'feedback-carousel': FeedbackCarousel,
        'feedback-section': FeedbackSection,
        'callout': Callout,
        'play-smart-play-safe': PlaySmartPlaySafe,
        'menuSectionMedia': MenuSectionMedia,
        'twoColumns': TextColumns,
        'doubleColumn': DoubleColumn,
        'image-with-text': ImageWithText,
        'disclaimer': Disclaimer,
        'emarsys': EmarsysBanner,
        'tour-travel-contact': TourTravelForm,
        'countdown': Countdown,
        'coming-soon': ComingSoon,
        'nco-events' : NCOEvents,
        'nco-hero' : NCOHero,
        'nco-opening' : NCOOpening,
        'careers-subheader' : SubHero,
        'me-story' : MEStory,
        'career-growth' : CareerGrowth,
        'current-openings' : CurrentOpenings,
        'culture-section': CultureSection,
        'mission-section': MissionSection,
        'benefits-section': BenefitsSection,
        'careers-slider' : CareersSlider,
        'accordian' : Accordian,
        'luck-modal' : LuckHomepageModal,
        'lost-pins-hero' : LostPinsHero,
        'lost-pins-text' : LostPinsText,
        'lost-pins-clues' : LostPinsClues,
        'card-section' : CardSection,
        'teaser': Teaser,
        'triple-column': TripleColumn,
        'season-pass-form': SeasonPassForm,
        'image-banner': ImageBanner
      }
    }
  },
  computed: {
    validSections() {
      let sections = []
      this.sections.map(s => {
        if(s) {
          sections.push(s)
        }
      })
      return sections
    }
  },
  methods: {
    getComponent(section) {
      if(section) {
        let type = section.sys.contentType.sys.id != 'section' ? section.sys.contentType.sys.id : section.fields.type
        return (type in this.componentList) ? this.componentList[type] : DefaultSection
      } else {
        return
      }
    }
  }
}
</script>