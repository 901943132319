<template>
  <div class="image-banner">
    <img :src="image" :alt="content.title" />
  </div>
</template>

<script>
export default {
  props: [
    'content'
  ],
  computed: {
    isMobile() {
      return this.$mq === 'sm' || this.$mq === 'md'
    },
    image() {
      if (this.isMobile && this.content.backgroundImageMobile) {
        return this.content.backgroundImageMobile.fields.file.url
      } else {
        return this.content.backgroundImageDesktop.fields.file.url
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.image-banner {
  width: 100%;
  padding-top: 40px;

  @media (min-width: $sm) {
    padding-top: 20px;
  }
  img {
    width: 100%;
    height: auto;
  }
}
</style>