<template>
  <div>
    <div v-if="rteLang" class="rte" v-html="rteLang" />
    <div v-if="rteLang" class="rte-spacer" v-html="rteLang" />
    <header id="site-header" class="z-40 header"
      :class="`${className} ${rteLang ? 'has-rte' : ''} ${isFoodMenu ? 'bg-transparent':''}`">
      <div class="hidden lg:flex contrast-controller absolute items-center cursor-pointer"
        v-on:click="toggleContrast()">
        <div class="mr-1">High Contrast</div>
        <div class="contrast-toggle border border-white rounded relative w-10 h-3">
          <div
            :class="`contrast-switch ${highContrast ? 'switch-on' : ''} border border-white rounded bg-white text-black absolute flex items-center justify-center w-6 h-3`">
            {{ highContrast ? 'On' : 'Off'}}</div>
        </div>
      </div>
      <div class="primary">
        <nuxt-link to="/">
          <img class="brand" alt="Main Event Logo"
            :src="`${highContrast ? '//images.ctfassets.net/3dar4x4x74wk/ris4qQHhncvgU9MyoeoWk/ebd259bac41495ae17c6e11c2c02a83f/logo.svg' : '//images.ctfassets.net/3dar4x4x74wk/cZZNUCdSENJnx4PZ6FLZs/7d534bbd01fc55c6392bc1618a192fae/logo.svg'}`" />
        </nuxt-link>

        <nav class="hidden">
          <div class="link-wrapper relative" v-for="(link, index) in links" :key="index">
            <nuxt-link v-if="link.fields.internalLink" :to="pageLink(link.fields.url)">{{link.fields.title}}</nuxt-link>
            <a v-else :href="link.fields.url">{{link.fields.title}}</a>
            <div class="sublinks" v-if="link.fields.subLinks">
              <div v-for="(sublink, subindex) in link.fields.subLinks" :key="subindex">
                <nuxt-link v-if="sublink.fields.internalLink"
                  :class="$route.path === '/' && index === 0  && 'nuxt-link-exact-active'"
                  :to="subPageLink(sublink.fields.url)">{{sublink.fields.title}}</nuxt-link>
                <a v-if="sublink.fields.internalLink == false"
                  :class="$route.path === '/' && index === 0  && 'nuxt-link-exact-active'"
                  :href="sublink.fields.url">{{sublink.fields.title}}</a>
              </div>
            </div>
          </div>
        </nav>

        <div class="ml-auto flex items-center toggle-wrapper">
          <a class="toggle-menu flex cursor-pointer outline-none ml-auto" @click="toggleMobileMenu">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="21px" height="14px" viewBox="0 0 21 14"
              enable-background="new 0 0 21 14" xml:space="preserve">
              <g>
                <defs>
                  <path id="SVGID_1_" d="M1,0h19c0.553,0,1,0.447,1,1s-0.447,1-1,1H1C0.447,2,0,1.553,0,1S0.447,0,1,0z" />
                </defs>
                <use xlink:href="#SVGID_1_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" />
                <clipPath id="SVGID_2_">
                  <use xlink:href="#SVGID_1_" overflow="visible" />
                </clipPath>
                <g clip-path="url(#SVGID_2_)">
                  <defs>
                    <rect id="SVGID_3_" x="-284" y="-16" width="320" height="4548" />
                  </defs>
                  <use xlink:href="#SVGID_3_" overflow="visible" />
                  <clipPath id="SVGID_4_">
                    <use xlink:href="#SVGID_3_" overflow="visible" />
                  </clipPath>
                  <rect x="-5" y="-5" clip-path="url(#SVGID_4_)" width="31" height="12" />
                </g>
              </g>
              <g>
                <defs>
                  <path id="SVGID_5_" d="M1,6h19c0.553,0,1,0.447,1,1s-0.447,1-1,1H1C0.447,8,0,7.553,0,7S0.447,6,1,6z" />
                </defs>
                <use xlink:href="#SVGID_5_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" />
                <clipPath id="SVGID_6_">
                  <use xlink:href="#SVGID_5_" overflow="visible" />
                </clipPath>
                <g clip-path="url(#SVGID_6_)">
                  <defs>
                    <rect id="SVGID_7_" x="-284" y="-16" width="320" height="4548" />
                  </defs>
                  <use xlink:href="#SVGID_7_" overflow="visible" />
                  <clipPath id="SVGID_8_">
                    <use xlink:href="#SVGID_7_" overflow="visible" />
                  </clipPath>
                  <rect x="-5" y="1" clip-path="url(#SVGID_8_)" width="31" height="12" />
                </g>
              </g>
              <g>
                <defs>
                  <path id="SVGID_9_"
                    d="M1,12h19c0.553,0,1,0.447,1,1s-0.447,1-1,1H1c-0.553,0-1-0.447-1-1S0.447,12,1,12z" />
                </defs>
                <use xlink:href="#SVGID_9_" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" />
                <clipPath id="SVGID_10_">
                  <use xlink:href="#SVGID_9_" overflow="visible" />
                </clipPath>
                <g clip-path="url(#SVGID_10_)">
                  <defs>
                    <rect id="SVGID_11_" x="-284" y="-16" width="320" height="4548" />
                  </defs>
                  <use xlink:href="#SVGID_11_" overflow="visible" />
                  <clipPath id="SVGID_12_">
                    <use xlink:href="#SVGID_11_" overflow="visible" />
                  </clipPath>
                  <rect x="-5" y="7" clip-path="url(#SVGID_12_)" width="31" height="12" />
                </g>
              </g>
            </svg>

            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="15.436px" height="15.436px"
              viewBox="0 0 15.436 15.436" enable-background="new 0 0 15.436 15.436" xml:space="preserve">
              <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF"
                d="M1.708,0.293l13.435,13.436c0.39,0.391,0.39,1.023,0,1.414
              c-0.391,0.391-1.024,0.391-1.415,0L0.293,1.707c-0.39-0.391-0.39-1.023,0-1.414C0.684-0.098,1.317-0.098,1.708,0.293z" />
              <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF"
                d="M0.293,13.729L13.728,0.293c0.391-0.391,1.024-0.391,1.415,0
              c0.39,0.391,0.39,1.023,0,1.414L1.708,15.143c-0.391,0.391-1.024,0.391-1.415,0C-0.097,14.752-0.097,14.119,0.293,13.729z" />
            </svg>
          </a>
          <div class="answers-layout pr-2 hidden lg:block">
            <div class="search-bar" />
          </div>

          <div class="location hidden lg:flex ml-auto">
            <span class="location-icon" @click="toggleLocationDropdown">
              <img
                src="//images.ctfassets.net/3dar4x4x74wk/48tuz5vTUYm0Iv9SrsKGNz/a95786b0d835fb743253ba3797a30f20/pin-alt.svg"
                alt="Location Icon" />
            </span>
            <div class="location-title" @click="toggleLocationDropdown">
              <div v-if="location">
                {{location.centerName}}
                <div v-if="hours" class="font-normal normal-case tracking-tight">{{ formatTime(hours) }}</div>
              </div>
              <span v-else>Find Me</span>
            </div>
            <span class="location-dropdown-icon flex items-center" @click="toggleLocationDropdown">
              <svg width="15px" height="9px" viewBox="0 0 15 9" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                  stroke-linejoin="round">
                  <g id="Header-2" transform="translate(-1164.000000, -11.000000)" stroke="#FFFFFF">
                    <polyline id="Line"
                      transform="translate(1171.500000, 15.500000) scale(-1, -1) rotate(90.000000) translate(-1171.500000, -15.500000) "
                      points="1175.5 9 1167.5 15.5 1172.64279 19.6785202 1175.5 22"></polyline>
                  </g>
                </g>
              </svg>
            </span>
            <location-dropdown :show="showLocationDropdown" :error="locError" />
          </div>

          <div class="divider"></div>

          <a :class="`cart-icon relative ${cartFull}`" v-on:click.prevent="toggleCart" title="Cart">
            <img
              src="//images.ctfassets.net/3dar4x4x74wk/7DyVKpJXTsg2e8DT0f8yVD/c64540d6d47ad07e5d9bb0ca06544edd/cart.svg"
              alt="Cart Icon" />
          </a>

          <nuxt-link :to="`/${loggedIn ? 'account' : 'login'}/`" class="account-icon"
            :title="loggedIn ? 'Account' : 'Login'">
            <img
              src="//images.ctfassets.net/3dar4x4x74wk/26r0Nl2rZEyFuUJVNTMsEi/bbdb0d3d630f0f7d74524b4a2ac58591/avatar.svg"
              alt="User Icon" />
          </nuxt-link>

        </div>
      </div>

      <transition enter-active-class="animated fadeInDown" leave-active-class="animated fadeOutUp">
        <nav v-show="showMobileMenu" class="mobile absolute lg:hidden">
          <div class="location">
            <span class="location-icon" @click="toggleLocationDropdown">
              <img
                src="https://images.ctfassets.net/3dar4x4x74wk/eDMQYgG3MpKx1SPo7VvX5/af43d851f2fd89ab460603e2a478f629/pin.svg"
                alt="Location Icon" />
            </span>
            <client-only>
              <span class="location-title" @click="toggleLocationDropdown">
                {{ location == false ? 'Find Me' : this.location ? this.location.centerName : 'Find Me' }}
                <!-- Example for NCO Additions Below -->
                <!-- <div v-if="location.centerName === 'Montclair'" class="font-normal normal-case tracking-tight">Coming Soon!</div> -->
                <div v-if="hours" class="font-normal normal-case tracking-tight">{{ formatTime(hours) }}</div>
              </span>
            </client-only>
            <span class="location-dropdown-icon flex items-center" @click="toggleLocationDropdown">
              <svg width="15px" height="9px" viewBox="0 0 15 9" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                  stroke-linejoin="round">
                  <g id="Header-2" transform="translate(-1164.000000, -11.000000)" stroke="#FFFFFF">
                    <polyline id="Line"
                      transform="translate(1171.500000, 15.500000) scale(-1, -1) rotate(90.000000) translate(-1171.500000, -15.500000) "
                      points="1175.5 9 1167.5 15.5 1172.64279 19.6785202 1175.5 22"></polyline>
                  </g>
                </g>
              </svg>
            </span>
          </div>
          <div v-for="(link, index) in links" :key="index">
            <div class="mobile-menu-link-wrapper" @click="toggleMobileMenu" v-if="!link.fields.subLinks">
              <nuxt-link :to="pageLink(link.fields.url)" v-html="link.fields.title" />
            </div>
            <div class="mobile-menu-link-wrapper" v-else>
              <a v-html="link.fields.title" @click="showSublinks($event)" />
              <div class="sublinks" v-if="link.fields.subLinks">
                <div @click="toggleMobileMenu">
                  <div v-for="(sublink, subindex) in link.fields.subLinks" :key="subindex">
                    <nuxt-link v-if="sublink.fields.internalLink" :class="index === 0  && 'nuxt-link-exact-active'"
                      :to="subPageLink(sublink.fields.url)">{{sublink.fields.title}}</nuxt-link>
                    <a v-if="sublink.fields.internalLink == false" :class="index === 0  && 'nuxt-link-exact-active'"
                      :href="sublink.fields.url">{{sublink.fields.title}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a :class="`cart-icon relative ${cartFull}`" v-on:click.prevent="toggleCart" title="Cart">
            <img src="~/assets/img/icons/cart.svg" alt="Cart Icon" />
          </a>
          <div class="answers-layout">
            <div class="search-bar2" />
          </div>
          <div @click="toggleMobileMenu">
            <nuxt-link :to="`/${loggedIn ? 'account' : 'login'}/`" class="account-icon"
              :title="loggedIn ? 'Account' : 'Login'">
              <img src="~/assets/img/icons/avatar.svg" alt="User Icon" />
              Log In
            </nuxt-link>
            <div class="contrast-controller absolute flex items-center cursor-pointer" v-on:click="toggleContrast()">
              <div class="mr-1">High Contrast</div>
              <div class="contrast-toggle border border-white rounded relative w-10 h-3">
                <div
                  :class="`contrast-switch ${highContrast ? 'switch-on' : ''} border border-white rounded bg-white text-black absolute flex items-center justify-center w-6 h-3`">
                  {{ highContrast ? 'On' : 'Off'}}</div>
              </div>
            </div>
          </div>
        </nav>
      </transition>

      <location-dropdown class="mobile-location-dropdown" :show="showLocationDropdown" :error="locError" />
      <cart :show="showCart" />
    </header>
  </div>
</template>

<script>
import LocationDropdown from '~/components/locations/LocationDropdown.vue'
import Cart from '~/components/common/Cart.vue'
import moment from 'moment'

const states = require('~/assets/config/states.json')

export default {
  components: {
    LocationDropdown,
    Cart
  },
  data: function () {
    return {
      info: {},
      showMobileMenu: false,
      showLocationDropdown: false,
      showCart: false,
      className: '',
      finding: true,
      locError: false,
      coords: null,
      isFoodMenu: false
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
    window.addEventListener("resize", this.hideMobileMenu)

    // Transparent header by default
    if (this.$route.name == 'index' || this.$route.name == 'our-story') {
      this.className = 'index'
      setTimeout(() => {
        this.className = 'index transition'
      }, 100)
    }

    if (window.location.pathname == '/menu/bar/' || window.location.pathname == '/menu/events/' || window.location.pathname == '/delivery/') {
      document.querySelectorAll('[href="/menu/food/"]')[0].classList.add('nuxt-link-active')
    }
    // ANSWERS.init({
    //   apiKey: '13c46c2cbef52278daf9a9d34ac27753',
    //   experienceKey: 'main-event-answers',
    //   businessId: '2058308',
    //   experienceVersion: 'PRODUCTION',
    //   locale: 'en',
    //   onReady: function () {
    //     this.addComponent('SearchBar', {
    //       container: '.search-bar',
    //       name: 'search-bar',
    //       redirectUrl: '/search',
    //       placeholderText: 'Search…',
    //       submitIcon: 'magnifying_glass'
    //     });
    //     this.addComponent('SearchBar', {
    //       container: '.search-bar2',
    //       name: 'search-bar2',
    //       redirectUrl: '/search',
    //       placeholderText: 'Search…',
    //       submitIcon: 'magnifying_glass'
    //     });
    //   }
    // })

    this.isFoodMenu = this.$route.name == 'menu-food' || this.$route.name == 'food'
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.hideMobileMenu);
  },
  watch: {
    '$route.path': function (e) {
      this.current = e
      const header = document.querySelector('header')
      if (window.location.pathname == '/' || this.$route.name == 'our-story') {
        header.classList.add('index')
      } else {
        header.classList.remove('index')
        header.classList.add('transition')
      }
      this.isFoodMenu = this.$route.name == 'menu-food'
      // if (window.location.pathname == '/menu/bar/' || window.location.pathname == '/menu/events/' || window.location.pathname == '/delivery/') {
      //   document.querySelectorAll('[href="/menu/food/"]')[0].classList.add('nuxt-link-active')
      // } else {
      //   document.querySelectorAll('[href="/menu/food/"]')[0].classList.remove('nuxt-link-active')
      // }
    }
  },
  computed: {
    location() {
      return this.$store.state.location
    },
    locationLink() {
      if (this.location) {
        return `/locations/${states[this.location.state].toLowerCase()}/${this.location.centerName.toLowerCase()}`
      } else {
        return null
      }
    },
    loggedIn() {
      return this.$store.state.loggedIn
    },
    links() {
      return this.$store.state.info.menus.main.fields.children
    },
    messageBanner() {
      return this.$store.state.info.menus.main.fields.messageBanner
    },
    hours() {
      return this.location && this.$store.state.centerData[this.location.centerId] && this.$store.state.centerData[this.location.centerId].hours ? this.$store.state.centerData[this.location.centerId].hours[moment().format('dddd').toLowerCase()] : null
    },
    cart() {
      return this.$store.state.cart
    },
    cartFull() {
      if (this.cart.summary) {
        return 'full'
      } else {
        return null
      }
    },
    // rte() {
    //   if(this.rteLang) {
    //     return 'rte'
    //   } else {
    //     return null
    //   }
    // },
    rteLang() {
      let rte = null
      // RTE Over ride example
      // if (this.location && this.location.centerId === 'WLM') {
      //   rte = 'Newark will be closed for a Rent the Center Event on October 1st from 11am-3:30pm'
      // }
      if ((this.location && this.$store.state.centerData[this.location.centerId]) || this.$route.name == 'locations-state-id') {
        let name = null
        let rentals = []
        let addHours = null
        if (this.$route.name == 'locations-state-id') {
          Object.keys(this.$store.state.centerData).map(cid => {
            if (this.$store.state.centerData[cid].path == `${this.$route.params.state}/${this.$route.params.id}/`) {
              name = this.$store.state.centerData[cid].name
              rentals = this.$store.state.centerData[cid].rentals
              addHours = this.$store.state.centerData[cid].addHours
            }
          })
        } else {
          name = this.location.centerName
          rentals = this.$store.state.centerData[this.location.centerId].rentals
          addHours = this.$store.state.centerData[this.location.centerId].addHours
        }
        let now = moment()
        let activeRentals = []
        rentals.map(r => {
          let startDate = moment(r.startDate).add(1, 'day') // until midnight the following day CONSIDER: moment(`${r.startDate} ${r.endTime}`)
          let warnDate = moment(r.startDate).subtract(14, 'days')
          if (now >= warnDate && now < startDate) {
            activeRentals.push({
              date: r.startDate,
              from: r.startTime,
              to: r.endTime
            })
          }
        })
        if (activeRentals.length > 0) {
          activeRentals.sort((a, b) => {
            return a.date < b.date ? -1 : 1
          })
          rte = `${name} will be closed for ${activeRentals.length > 1 ? '' : 'a '}Rent the Center Event${activeRentals.length > 1 ? 's' : ''} on`
          activeRentals.map((r, i) => {
            if (i > 0) {
              rte += ','
            }
            if (activeRentals.length > 1 && i >= activeRentals.length - 1) {
              rte += ' and'
            }
            rte += ` ${r.date} ${r.from}-${r.to}`
          })
        }

        if (rte && addHours) {
          rte += `<br />${addHours}`
        } else if (addHours) {
          rte = addHours
        }
      } else if (this.messageBanner) {
        rte = this.messageBanner
      }
      return rte
    },
    highContrast() {
      return this.$store.state.highContrast
    }
  },
  methods: {
    formatTime(hours) {
      // NCO Coming Soon display in header if location is selected
      // if (this.location.centerId === 'MON') {
      //   return 'Coming Soon!'
      // } else 
      if (hours.isClosedWholeDay == true) {
        return 'Closed Today'
      } else {
        let open = hours.openIntervals[0].start.split(':')
        let openTime = moment().hour(open[0]).minute(open[1])
        let closed = hours.openIntervals[hours.openIntervals.length - 1].end.split(':')
        let closedTime = moment().hour(closed[0]).minute(closed[1])
        return `Open Today: ${openTime.format(`h${openTime.minute() > 0 ? ':mm' : ''} a`)} - ${closedTime.format(`h${closedTime.minute() > 0 ? ':mm' : ''} a`)}`
      }
    },
    showSublinks(e) {
      let sublinks = document.querySelectorAll('.mobile .sublinks')
      let i
      for (i = 0; i < sublinks.length; i++) {
        sublinks[i].style.display = 'none'
      }
      e.target.nextElementSibling.style.display = 'block'
    },
    async toggleLocationDropdown() {
      this.showLocationDropdown = !this.showLocationDropdown
      this.showCart = false
      if (!this.$store.state.finding && this.showLocationDropdown && !this.$store.state.searched) {
        console.log(navigator.geolocation)
        if (this.$store.state.zipcode) {
          this.$store.commit('finding', true)
          let locations = await this.$api.getLocations({ zipcode: this.$store.state.zipcode })
          this.$store.commit('setLocations', locations)
          this.$store.commit('finding', false)
          this.$store.commit('searched', true)
        } else if (!this.coords && this.$store.state.locations.length < 1 && navigator.geolocation) {
          console.log('get coordinates')
          navigator.geolocation.getCurrentPosition(this.getCoordinates, this.noServices)
          this.$store.commit('finding', true)
        } else if (this.coords) {
          console.log('have coordinates')
          // this.searchCoords()
        } else {
          console.log('must use zipcode')
        }
      }
    },
    getCoordinates(position) {
      this.coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      this.searchCoords()
    },
    noServices() {
      this.$store.commit('finding', false)
      this.locError = true
    },
    async searchCoords() {
      this.$store.commit('finding', true)
      let locations = await this.$api.getLocations(this.coords)
      this.$store.commit('setLocations', locations)
      this.$store.commit('finding', false)
      this.$store.commit('searched', true)
    },
    toggleCart() {
      this.showCart = !this.showCart
      this.showLocationDropdown = false
    },
    toggleMobileMenu() {
      let sublinks = document.querySelectorAll('.mobile .sublinks')
      let i
      for (i = 0; i < sublinks.length; i++) {
        sublinks[i].style.display = 'none'
      }
      let header = document.querySelector('header')
      this.showMobileMenu = !this.showMobileMenu
      if (this.showMobileMenu == true) {
        header.classList.add('mobile-menu-open')
      } else {
        this.showLocationDropdown = false
        this.showCart = false
        header.classList.remove('mobile-menu-open')
        window.scrollTo(window.pageXOffset, window.pageYOffset - 1)
        window.scrollTo(window.pageXOffset, window.pageYOffset + 1)
      }
    },
    hideMobileMenu() {
      this.showMobileMenu = false
      this.showLocationDropdown = false
      this.showCart = false
      let header = document.querySelector('header')
      header.classList.remove('mobile-menu-open')
    },
    handleScroll() {
      let header = document.querySelector('header')
      let cart = document.querySelector('.cart')
      // let headerHeight = header.clientHeight
      let headerHeight = 65
      let scrollY = window.scrollY

      if (scrollY > headerHeight) {
        header.classList.add('opaque')
      } else {
        header.classList.remove('opaque')
      }
    },
    pageLink(link) {
      if (link == '/book/' && this.cart.type) {
        return this.subPageLink(`${link}${this.cart.type}/`)
      } else {
        return link
      }
    },
    subPageLink(link) {
      let newLink = null
      switch (link) {
        case '/book/birthday/':
        case '/book/bowling/':
          if (this.cart.type && link.match(this.cart.type) && this.$store.state.bookFlow) {
            let lastStep = this.$store.state.bookFlow.fields.steps[0].fields.slug
            this.$store.state.bookFlow.fields.steps.map(s => {
              if (this.cart[s.fields.slug] != null) {
                lastStep = s.fields.slug
              }
            })
            newLink = `${link}${lastStep}/`
          } else {
            newLink = link
          }
          break
        default:
          newLink = link
      }
      return newLink
    },
    toggleContrast() {
      console.log('toggleContrast')
      this.$store.commit('setHighContrast', !this.$store.state.highContrast)
      console.log('contrast', this.$store.state.highContrast)
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/variables';

#site-header.mobile-menu-open {
  background: rgba(31, 31, 30, 1) !important;

  .toggle-menu {
    svg:first-of-type {
      display: none;
    }

    svg:last-of-type {
      display: block;
    }
  }

  .location {
    margin-top: 10px;
    margin-bottom: 15px;

    .location-icon img {
      height: 20px;
      min-width: 0;
    }

    .location-title {
      padding-left: 8px;
    }

    .location-dropdown-icon {
      cursor: pointer;
    }
  }
}

#site-header.index {
  background: none;
  transition: all 0s;
}

#site-header.transition {
  transition: all .3s;
}

// #site-header.rte {
//   padding-top: 56px;
//   &:before {
//     content: attr(data-rte-lang);
//     position: absolute;
//     top: 0;
//     right: 0;
//     left: 0;
//     padding: .25rem .5rem;
//     text-align: center;
//     background: $pink;
//   }
// }

.link-wrapper .nuxt-link-active:after {
  content: '';
  border-bottom: 2px solid #fff;
  position: absolute;
  bottom: 7px;
  left: 12px;
  right: 12px;

  @media (max-width: 1130px) {
    left: 10px;
    right: 10px;
  }
}

.sublinks .nuxt-link-active:after {
  display: none;
}

.cart-icon.full:after {
  content: ' ';
  position: absolute;
  top: -8px;
  right: 10px;
  background-color: red;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.contrast-controller {
  font-size: .65rem;
  transition: .2s;

  @media (max-width: calc(#{$lg} - #{1px})) {
    bottom: 12px;
    right: 12px;
  }

  @media (min-width: $lg) {
    top: 12px;
    left: 12px;
  }

  @media (min-width: 1170px) {
    top: 12px;
    left: 46px;
  }
}

.contrast-switch {
  top: -1px;
  left: 0;
  padding: .1rem;
  font-size: .5rem;
  transition: .1s;

  &.switch-on {
    left: calc(100% - 1.5rem);
  }
}

#site-header.opaque .contrast-controller {
  @media (min-width: $lg) {
    top: -20px;
  }
}
</style>